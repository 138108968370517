@media screen and (max-width: 1366px) {
    .caption h1 {
        font-size: 64px;
    }
    
    .caption p {
        font-size: 21px;
    }
    .hungama_game .box {
        padding: 40px 24px;
    }
    
    .hungama_game .box h4 {
        font-size: 20px;
    }
    .fairplay h5 {
        font-size: 14px;
    }
    .nav-link { 
        font-size: 16px;
        margin: 0 5px; 
    }
    .offers {padding: 100px 0;}
    .heading {
        margin-bottom: 40px;
    }
    .frequent-sec {
        padding: 70px 0px 100px;
    }
    .why_game {
        padding-bottom: 50px;
    }
    .download_app {
        top: -90px;
    }
 
  }


  @media screen and (max-width: 1199px) {
    .app_icon img {
        max-width: 200px;
    }
    .frequent-sec .frequent-sec .accordion-button {
        
        padding: 18px 0px;
       
      }
    .caption h1 {
        font-size: 50px;
    }
    
    .caption p {
        font-size: 18px;
    }
    
    .download_app .box h3 {
        min-height: 64px;
    }
    
    .heading {
        margin-bottom: 36px;
    }
   
    .hungama_game .box {
        padding: 32px 15px;
    }
    
    .why_game {
        padding-bottom: 50px;
    }
    .heading h2{font-size:32px;}
    .our_game { padding: 60px 0 70px;}
    .offers{padding:100px 0;}
    .offers_main h3{font-size:24px;}
    .footer_logo img {
        max-height: 50px;
    }

/* MM */
.frequent-sec {
    padding: 50px 0px 109px;
  }
 
  .accordion {
    max-width: 746px;
  }
  .download-sec .row {
    max-width: 846px;
  }
  .download-sec::before {
    width: 76px;
  }
  .download-sec::after {
    width: 553px;
    height: 210px;
  }
  .download-inner li {
    margin-bottom: 24px;
  }
  .our_game .slick-dots {
    margin: 0;
    padding: 0;
}
.hungama_game {
    margin: 80px 0 0 0 ;
    position: relative;
    padding: 80px 0;
}

  }


  @media screen and (max-width: 991px) {
    .app_icon img {
        max-width: 100%;
    }
    .download_app .box {
        border-radius: 10px;
    }
    .frequent-sec .accordion-button {        
        padding: 15px 0px;
       
      }
      .offers_main{
        border-radius: 20px;
        padding: 10px;
      }


    .logo a img {
        max-height: 40px;
    }
    
    .nav-link {
        font-size: 12px;
        margin: 0;
    }
    
    .btn {
        font-size: 16px;
        padding: 12px 15px;
    }
    
    .caption h1 {
        font-size: 40px;
    }
    
    .caption p {
        font-size: 15px;
    }
    
    .download_app .box h3 {
        font-size: 18px;
        min-height: 50px;
    }
    
    .download_app {
        top: -100px;
    }
    
    .hungama_game .box .icon img {
        max-height: 110px;
    }
    .hungama_game .box .icon{height:100px;}
    .hungama_game .box {
        flex-direction: column;
        align-items: center;
        border-radius: 20px;
        padding: 10px;
    }
    
    .hungama_game .box .text {
        width: 100%;
        text-align: center;
    }
    
    .hungama_game .box h4 {
        font-size: 18px;
    }
    
    .our_game {
        padding: 20px 0;
    }
    
    .heading p {
        font-size: 18px;
    }
    
    .heading h2 {
        font-size: 28px;
        margin-bottom: 5px;
    }
    
    header {
        padding: 20px 0;
    }
    
    .banner {
        padding:72px 0 120px;
    }
    .offers {
        padding: 60px  0;
    }
    .offers_main h3{font-size:15px;
    margin-top: 20px;}

    .footer{padding:32px 0;}

/* MM */

.frequent-sec::before {
    opacity: 0.3;
    width: 83px;
  }
  .download-sec::before {
    z-index: -1;
  }
  .download-sec::after {
    display: none;
  }
  .download-sec figure img {
    width: 100%;
    height: 100%;
  }
  .frequent-sec::after {
    z-index: -1;
    opacity: 0.2;
  }
  .frequent-sec {
    padding: 28px 0px 99px;
  }
  .frequent-sec .frequent-sec .accordion-button {
    font-size: 18px;
  }

  .our_game_outer h4 a{font-size:20px;}

}



  @media screen and (max-width: 767px) {


    .fantasy_screen{padding:6px;}

    .slick-dots {padding: 0;}

    .footer{padding:32px 0 2px 0;}
 
 
    .banner .caption {position: absolute;width:520px;top: 50%;text-align: center;left: 0;right: 0;margin: auto;background: #0b1823b0;border-radius: 15px;padding: 20px;}
    
    .caption h1 {
        font-size: 28px;
    }
    .footer_nav {
        padding: 0;
        margin-bottom: 20px;
    }
    .banner .caption .slick-dots {
        margin-bottom: 0;
    }
   
    
    .download_app .box {
        height: calc(100% - 20px);
        margin-bottom: 20px;
    }
    
    .download_app {
        top: -64px;
    }
    .heading p {
        font-size: 16px;
    }
    .app_icon img {
        max-width: 150px;
    }
    .banner{position: relative;}
    .banner {
        padding: 36px 0 60px;
    }

    .offers {
        padding: 36px  0;
    }

    .offers_main h3{font-size:18px;}
    .offers_main{border-radius:20px; height:calc(100% - 20px); margin-bottom:20px;}
    .offers_main img {
        max-height: 180px;
    }

    .our_game .slick-dots{padding-left:0;margin-bottom:0;}
    .desclaimer h6{margin-bottom:10px;}
    .footer ul li a{padding:2px 0; font-size:15px;}
    .footer ul{margin-bottom:0;}

    .footer .col-sm-6 {
        margin-bottom: 30px;
    }

/* MM */

.download-sec figure img {
    max-width: 198px;
  }
  
  .download-sec figure {
    margin: 0;
  }
  .frequent-sec {
    padding: 28px 0px 70px;
}
.navbar-toggler {
    background: #11ffbd;
    padding: 0;
    width: 36px;
    height: 32px;
    border-radius: 2px;
}

.navbar-toggler-icon {background-size: 22px;margin-top: -1px;margin-left: -1px;} 

header .btn {
    font-size: 13px;
    padding: 10px 15px;
    border-radius: 5px;
    line-height: 1.2;
}
.navbar-collapse {
    position: absolute;
    background: #0b1823;
    left: 0;
    right: 0;
    width: 100%;
    top: 72px;
}

.navbar {
    position: inherit;
}

.nav-link {
    color: #fff;
    padding: 10px 15px;
    font-size:15px;
    border-bottom: 1px solid #f1f1f155;
}
.why_game {
    margin-top: 0;
}.hungama_game {
    margin: 30px 0 0 0;
    position: relative;
    padding: 36px 0;
}

.why_game {
    margin-top: 0;
}

.our-game-figure img {
    max-height: 240px;
    margin: auto;
}

.our-game-figure {
    text-align: center;
}

.our-game-figure {
    padding: 15px;
}

.our_game_outer a:not(.our_game_outer h4 a) {
    display: inline-block;
    width: 100%;
    text-align: center;
    background: #3444522b;
    margin: 10px 0;
    border-radius: 10px;
}

.our_fantasy {
    padding: 50px 0;
}


.download-sec { padding:40px 0 50px 0;}

  }


  @media screen and (max-width: 575px) {

    .download-sec { padding:32px 0 36px 0;}

    .hungama_game{padding:20px 0;}

    .banner {
        padding: 36px 0 0;
    }
    .frequent-sec .frequent-sec .accordion-button {
        padding: 15px 0px;
    }

    .banner .caption{width:calc(100% - 32px);}
    
    .download_app .box { border-radius: 10px;}
    .heading {
        margin-bottom: 20px;
    }
    .heading h2{font-size:24px;}
    .why_game,.our_game {padding:20px 0; }
    .hungama_game .box{padding:15px;}
    .banner .caption{transform:translateY(-10%);}
    .caption h1 {
        font-size: 24px;
    }
    .banner .caption .slick-dots{padding-left:0;}


    .offers {
        padding: 30px  0;
    }
    .offers_main{padding:20px;}
    .offers_main img {
        max-height: 150px;
    }

    .footer {
        padding: 32px 0;
    }
    
    .fairplay {
        justify-content: center;
    }
    
    .fairplay h5 {
        font-size: 18px;
    }
    
    .footer ul li a {
        padding: 3px 0;
    }

    .footer .col-sm-6 {
        margin-bottom: 20px;
    }

    .footer ul li:last-child a{border-bottom:none;}
    .fairplay:last-child{margin-bottom:0;}
    .footer .col-sm-6:last-child{margin-bottom:0;}

    /* MM */

    .frequent-sec .frequent-sec .accordion-button {
        font-size: 16px;
      }
      .store img {
        max-width: 150px;
    }
      .download-inner h4 {
        font-size: 18px;
        margin-bottom: 12px;
      }
      .download-sec figure {
        margin: 22px auto 0px;
        display: block;
        text-align: center;
      }
   
      .frequent-sec::before,
      .frequent-sec::after {
        display: none;
      }

      .frequent-sec {
        padding: 30px 0px 40px;
    }
.freq-accordian{padding: 0;}
.frequent-sec .accordion-item:first-of-type {
    padding: 0;
}

.frequent-sec .accordion-item {
    padding:0;
} 
.frequent-sec .accordion-item:first-of-type{padding:0;}
 
.frequent-sec .accordion-button{font-size:16px; padding:15px 0;}

  }
  

@media screen and (max-width:479px) {
    .footer ul li a {font-size: 14px;}
    .footer ul li {
        width: 50%;
    }

    .total-player-point .point-sec h2 span {
        font-size: 13px;
    }

    .contest-feature li{ font-size:11px;}

}