a {
  text-decoration: none;
}

body {
}

p {
  color: #515151;
}

html,
body,
#root,
.App,
.AL_body {
  height: 100%;
}

img {
  max-width: 100%;
}

.AL_body {
  background: #040e38 url(../../images/inner_body_bg.png) no-repeat center top /
    cover;
  color: #b2b2b2;
}

.AL_wrap {
  max-width: 1600px;
  margin: 0 auto;
}

.middle_panel {
  width: 430px;
  padding: 0;
}

.middlePage_bg {
  /* background: url(../../images/middle_bg.png) no-repeat center top; */
  background: linear-gradient(181deg, #2a5560, #000) !important;
  background-size: cover;
  padding-top: 0;
}

.siginin_page .middlePage_bg {
  background: url(../../images/login_bg.png) no-repeat center top !important;
  background-size: cover !important;
}

.right_panel,
.left_panel {
  width: auto;
  padding: 60px 0px 45px;
  flex: 1;
}

.top_header {
  padding: 30px 0;
  background: url(../../images/top_headerBG.png) no-repeat center top;
  background-size: cover;
}
.middle_soccer_header {
  background: url(../../images/top_headerBG.png) no-repeat center top;
  background-size: cover;
}

.back_arrow a {
  font-size: 18px;
  color: #fff;
}

.inner_slider .caption {
  position: absolute;
  top: 0;
  padding: 14px 16px;
  max-width: 230px;
}

.inner_slider .caption h2 {
  color: #fff;
  font-size: 14px;
}

.inner_slider .caption p {
  margin: 0;
  color: #fff;
  font-size: 11px;
}

.my_contest {
  background: #13212d;
  padding: 16px 23px 10px;
  border-radius: 0px;
}

.contest_heading {
  padding: 0 10px 6px 10px;
}

.contest_heading h2 {
  margin: 0;
  color: #fff;
  font-size: 16px;
  font-family: "Kanit", sans-serif;
  margin-bottom: 5px;
}

.contest_heading a {
  color: #f7b32b;
  font-size: 13px;
  font-weight: bold;
  font-family: "Kanit", sans-serif;
}

.my_contest .panel {
  border-radius: 15px;
}

.panel_head h3 {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.panel_head span {
  font-size: 12px;
  color: #35b80f;
}

.my_contest .team1,
.my_contest .team2 {
  background-color: #b3bad1;
}

.team_name h4 {
  color: #000000;
  font-size: 14px;
}

.team_name h4 span {
  display: block;
  font-size: 11px;
  margin-top: 3px;
}

.team_name .team_icon {
  width: 42px;
  height: 42px;
  flex: 0 0 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #fff;
  overflow: hidden;
}
.team_name .team_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.match_time.active {
  background-color: #6ec631;
  color: #fff;
}

.match_time {
  color: #fff;
  background: #6ec631;
  border: 1px solid #b4e1ff;
  position: absolute;
  left: 51%;
  font-weight: 500;
  z-index: 9;
  transform: translate(-50%, 0px);
  padding: 5px 0px;
  border-radius: 24px;
  font-size: 13px;
  min-width: 91px;
  text-align: center;
}

.completed-match {
  border: none;
}

.panel_foot h5 {
  color: #b2b2b2;
  font-size: 13px;
}

.panel_foot a {
  filter: grayscale(100%);
  opacity: 0.7;
}

.panel_foot a:hover,
.panel_foot a.active {
  filter: grayscale(0);
  opacity: 1;
}

/* ms */

.bottom_checklist li {
  display: inline-block;
  text-align: center;
  color: #fff;
  padding: 0px 23px;
  border-right: 1px solid #283a9a;
  font-size: 19px;
}

.bottom_checklist li img {
  display: block;
  margin: 0 auto 22px;
}

.middlePage_bg .bottom_checklist li img {
  margin: 0 auto 14px;
  max-width: 25px;
}

.bottom_checklist li:last-child {
  border-right: none;
  padding-right: 0px;
}

.check-offer .btn-sm {
  display: inline-block;
  color: #fff;
  padding: 0px b;
  padding: 11px 15px;
  border: 1px solid #fff;
  border-radius: 8px;
}

.check-offer {
  font-size: 18px;
  max-width: 344px;
}

.download_app_icon h6,
.check-offer,
.bottom_checklist li {
}

.check-offer .btn-sm:hover {
  background: #071b6e;
}

.sort-by-sort .entry {
  border-radius: 5px;
  color: #010101;
  font-size: 12px;
  padding: 4px 9px;
  background: #ffffff;
  border: none;
  margin-right: 8px;
}

.sort-by-sort .entry.active {
  background-color: #263642;
  color: #fff;
}

.sort-by-sort .entry-soccer {
  border-radius: 5px;
  color: #263642;
  font-size: 12px;
  padding: 4px 9px;
  background: #fff;
  border: none;
  margin-right: 8px;
}

.sort-by-sort .entry-soccer.active {
  background-color: #263642;
  color: #fff;
}

.sort-by {
  background: #5b6f84;
  padding: 10px;
}

.sort-by span {
  display: block;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
}

.filter-button {
  background: #11ffbd;
  width: 39px;
  transition: 0.3s;
  text-align: center;
}

.filter-button:hover {
  background: linear-gradient(356deg, #11ffbd, #00946a);
}

.mega-contest-card {
  background: #13212d;
  margin-bottom: 10px;
  border-radius: 13px;
  overflow: hidden;
}

.mega-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 9px 13px;
  align-items: center;
}

.mega-wrapper .mega-contest span {
  font-weight: 600;
  color: #fff;
  font-size: 14px;
}

.mega-wrapper .mega-contest h5 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;

  margin: 0;
}

.mega-wrapper .entry-sec span {
  font-size: 13px;
  display: block;
  color: #f7b32b;
  font-weight: 500;
  margin-bottom: 4px;
}

.mega-wrapper .entry-sec {
  text-align: center;
}

.mega-wrapper .entry-sec a {
  background: #11ffbd;
  min-width: 80px;
  display: block;
  text-decoration: none;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
}

.contest-feature {
  background: #344452;
  padding: 5px 12px;
}

.contest-feature li {
  display: inline-block;
  font-size: 12px;
  margin-right: 10px;
  color: #b3bad1;
  white-space: nowrap;
}

.preview_team .team_preview {
  position: static;
}

.contest-feature li img {
  margin-right: 5px;
}

.contest-feature li:last-child {
  margin-right: 0px;
  display: flex;
}

.sport-left-sec span {
  color: #b3bad1;
  font-size: 13px;
}

.sport-left-sec .sport-lefter {
  color: #f7b32b;
}

.progress-bar {
  background: #11ffbd;
  border-radius: 0px;
}

.progress {
  border-radius: 0px;
  height: 5px;
  background: #344452;
}
.form-switch.form-check-reverse .form-check-input:checked {
  background-color: #11ffbd;
  border-color: #11ffbd;
}
.tooltip-inner {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  background: #263642;
  width: 100%;
}

.tooltip-inner .tooltip-arrow {
  border-top-color: #263642;
}

.tooltip-arrow {
  border-top-color: #263642;
}

.tool {
  white-space: nowrap;
  font-size: 9px;
}

.joined-contest h6 {
  margin: 0;
  font-size: 13px;
  color: #fff;
  margin-right: 13px;
}

.joined-contest span {
  width: 18px;
  display: inline;
  height: 18px;
  background: rgb(52 68 82);
  border-radius: 2px;
  margin-right: 12px;
  padding: 3px 5px;
  font-size: 10px;
  color: #f7b32b;
}

.entry-rate {
  font-size: 14px;
  text-align: right;
}

.entry-rate {
  font-size: 14px;
  text-align: right;
}

.team-contest-wrapper {
  background: #13212d;
  border-radius: 14px;
  overflow: hidden;
}

.team-contest-wrapper .team-header li {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}

.team-header {
  padding: 6px 19px;
}

.kcip-team h6 {
  margin: 0px;
  line-height: 30px;
  color: #fff;
  font-size: 14px;
}

.team-data-wrapper {
  background-image: url(../../../assets/images/half-field.png);
  padding: 10px 19px 10px;
}

.team-data-wrapper article figure {
  margin: 0 auto 9px auto;
  position: relative;
  display: inline-flex;
  justify-content: center;
}

.team-data-wrapper article figure div {
  background: #fff;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  border: 2px solid #e5b582;
}

.team-data-wrapper .flex-wrapper-team {
  flex: 1;
}

.team-data-wrapper article .team-notification {
  position: absolute;
  top: 0;
  right: 0;
  left: 27px;
  width: 20px;

  height: 20px;
  background: #003a58;
  color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  line-height: 0px;
  border: 1px solid #fff;
}

.inner-sec {
  height: 100px;
  border-radius: 13px;
  margin-top: 10px;
  margin-bottom: 0;
  overflow: hidden;
}

.team-data-wrapper figcaption h5 {
  color: #fff;
  font-size: 12px;
  margin: 0;
}

.wicket-rank ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.wicket-rank {
  padding: 7px 19px;
  background: #344452;
}
.wicket-rank.color-red {
  color: #f7b32b;
  margin: 0;
}

.wicket-rank ul li {
  font-size: 12px;
  color: #11ffbd;
}

.team-edit-sec {
  display: flex;
  justify-content: space-between;
  padding: 6px 19px;
}

.team-edit-sec a {
  color: #4e4e4e;
  font-size: 12px;
}

.lineup-sec-guide {
  color: #ff3030;
  font-size: 14px;
  padding: 9px 19px;
  background-color: #f5f5f5;
}

/* ms */

/* sm */
.splash_screen {
  background-image: url(../../images/splash.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.siginin_page .slick-slide img {
  display: block;
  margin: auto;
}

.siginin_page h2 {
  font-size: 25px;
  color: #fff;

  padding-top: 0;
}

.siginin_page p {
  font-size: 16px;
  max-width: 286px;
  margin: auto;
  margin-bottom: 28px;
}

.btn-green {
  font-size: 16px;
  color: #fff;
  border-radius: 8px;
  max-width: 100%;
  padding: 16px;
  background-color: #6ec631;
  display: flex;
  align-items: center;

  justify-content: center;
  border: 0;
}

.btn-green a {
  color: #fff;
}

.siginin_page input {
  border-radius: 8px;
  color: #fff;
  padding: 15px;
  background-color: #13212d;
  border: 1px solid #5b6f84;
}

.congratulations .bg-white {
  height: calc(100% - 54px);
}

.btn-green:hover,
.btn-green:hover a {
  background-color: #35b80f;
  color: #fff;
}

.referral-link a {
  color: #fb6d31;
  font-size: 16px;
}

.siginin_page form {
  padding: 0 30px;
}

.time_clock {
  display: flex;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 94px;
  height: 94px;
  margin: auto;
  color: #000;
  font-size: 19px;
}
.otp_input input[type="text"] {
  background: #364958;
  border: 1px solid #11ffbd !important;
  color: #fff;
  font-size: 20px;
}

.otp_input input[type="text"]:focus {
  outline: none;
  border-color: #11ffbd;
}

.otp_main {
  padding: 30px;
  margin: 0 24px;
}

.otp_page form .form-control.incorrect {
  border: 1px solid #c31235;
}

.otp_page form .form-control {
  margin: 5px;
  width: 51px;
  height: 54px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.wrong_inp {
  color: #11ffbd;
  font-size: 14px;
}

.wrong_inp:hover {
  color: #11ffbd;
  text-decoration: underline;
}

.otp_page p {
  font-size: 14px;
}

.otp_main h5 {
  font-size: 18px;
  color: #000;
}

.otp_main h6 {
  font-size: 18px;
  line-height: 1.4;
}
.otp_main h6 span {
  font-weight: bold;
}

.otp_page input[type="checkbox"] {
  font-size: 14px;
  color: #000;
}

.otp_page a {
  font-size: 14px;
}

.custom_check {
  display: inline-block;
  position: relative;
  cursor: pointer;
  line-height: 22px;
  padding-left: 10px;
  margin-top: 5px;
}

.custom_check input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0px 0px;
  z-index: 999;
}

.custom_check .check_indicator {
  height: 18px;
  width: 18px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #ffffff;
  border: 1px solid #13212d;
  border-radius: 2px;
}
.my_transaction .dropdown-menu:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #dfecf6;
  top: -5px;
  transform: rotate(45deg);
}
.custom_check input:checked + .info + .check_indicator:before {
  width: 5px;
  height: 10px;
  position: absolute;
  left: 6px;
  top: 2px;
  border: solid #fff;
  border-width: 0px 2px 2px 0px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
}

/* sm */

/**======================RR======================**/

/* width */
.custome_table::-webkit-scrollbar,
.mega-content-sec::-webkit-scrollbar,
.winning_tabs .tab-content::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

/* Track */
.custome_table::-webkit-scrollbar-track,
.mega-content-sec::-webkit-scrollbar-track,
.winning_tabs .tab-content::-webkit-scrollbar-track {
  background: #e1e1e1;
}

/* Handle */
.custome_table::-webkit-scrollbar-thumb,
.mega-content-sec::-webkit-scrollbar-thumb,
.winning_tabs .tab-content::-webkit-scrollbar-thumb {
  background: rgb(156, 156, 156);
  border-radius: 100px;
}

/* Handle on hover */
.custome_table::-webkit-scrollbar-thumb:hover,
.mega-content-sec::-webkit-scrollbar-thumb:hover,
.winning_tabs .tab-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.middle_content {
  position: relative;
}

.kxip {
  font-size: 13px;
  line-height: 1.2;
  position: relative;
}

.kxip small {
  font-size: 12px;
  color: #fff;
}

.kxip h5,
.rcbT h5 {
  font-size: 16px;
}

.team_head {
  padding: 0 24px;
  background: #153c46;
}

.used_team_member {
  background: #13212d;
  padding: 10px 12px;
}

.used_team_member ul li {
  background: #fff;
  margin: 4px 5px;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  color: #2b2b2b;
}

.my-tearms-tabs-custome .nav-link {
  margin: 0;
  font-size: 16px;
  color: #5b6f84;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 0;
  border: none;
  border-bottom: 3px solid transparent;
}

.my-tearms-tabs-custome {
  justify-content: space-between;
  padding: 0 24px;
  background-color: #0b1823;
  border-bottom: none;
}

.my-tearms-tabs-custome .nav-link.active,
.my-tearms-tabs-custome .nav-link:hover {
  border-color: #f7b32b;
  color: #f7b32b;
  background-color: transparent;
}
.pick-team .text-green {
  font-weight: normal;
}

.pick-team {
  background: #344452;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  font-size: 14px;
  margin: 10px;
  padding: 5px 10px;
  font-weight: 600;
}

.text-blue {
  color: #048cfb;
}

.custome_table thead th {
  background: #13212d;
  color: #f7b32b;
  border: none;
  padding: 10px 5px;
  font-weight: normal;
  font-size: 12px;
}
.pick-team .text-green {
  font-weight: normal;
}

.player_detail {
  display: flex;
}

.player_detail figure {
  margin: 0;
  width: 38px;
  height: 38px;
  border: 1px solid #e5b582;
  border-radius: 100px;
  overflow: hidden;
  flex: 0 0 38px;
}

.player_detail figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.player_detail figcaption {
  padding-left: 6px;
}

.player_detail h4 {
  color: #fff;
  font-size: 14px;
  margin-bottom: 2px;
  font-weight: bold;
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team_option {
  color: #9ca7af;
  font-size: 13px;
}

.team_option span {
  border-right: 1px solid #707070;
  padding-right: 5px;
  margin-right: 5px;
}

.team_option span:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
}

.custome_table thead,
.custome_table tbody {
  padding: 0 24px;
}

.used_team_member .add {
  background: #11ffbd;
  color: #000;
}

.custome_table thead th:first-child,
.custome_table tbody td:first-child {
  padding-left: 24px;
}

/* .custome_table tbody td:last-child {
  padding-right: 24px;
  position: relative;
  border-bottom-color: #1c2833;
} */

.plus-minus-tag {
  margin: 0;
  display: inline-block;
  cursor: pointer;
  float: right;
}

.creadit_grid a {
  display: inline-block;
  padding-right: 15px;
}

.custome_table .table tr td {
  vertical-align: middle;
  padding: 15px 6px;
  color: #fff;
  border-color: #ffffff12;
}

.text-dark-green,
.not-announce_tag {
  color: #f7b32b;
  position: relative;
  padding-left: 16px;
}

.not-announce_tag {
  color: #c31235;
}

.not-announce_tag:before {
  background-color: #c31235;
}

.announce_tag:before,
.not-announce_tag:before {
  width: 8px;
  height: 8px;
  background: #f7b32b;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 5px;
  border-radius: 50px;
}

.not-announce_tag:before {
  background-color: #11ffbd;
}

.active_row td {
  background: #344452;
}
.player-added:not(.active_row) td {
  background: #0b1823;
}

.player-added .plus {
  display: none;
}

.footer-action {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(176deg, #264c55, #0f2735);
  padding: 10px 15px;
}

.minus {
  display: none;
}

.player-added .minus {
  display: block;
}

.my-tearms-tabs .custome_table {
  height: calc(100vh - 338px);
  overflow-y: auto;
  padding-bottom: 85px;
  position: relative;
}

.footer-action .utline_large_btn {
  border: 1px solid #ffffff;
}

.footer-action .outline_large_btn {
  border-color: #11ffbd;
  padding: 15px 20px;
  font-size: 16px !important;
  font-weight: normal;
  color: #11ffbd;
}

.footer-action .large_btn {
  min-width: 117px;
}

.footer-action .outline_large_btn:hover {
  background: linear-gradient(356deg, #11ffbd, #00946a) !important;
  border-color: transparent;
  font-weight: 500;
}

.creadit-left-value {
  font-size: 24px;
  display: block;
  line-height: 1.1;
  padding: 0 15px;
  text-align: center;
  color: #fff;
}

.creadit-left-value small {
  display: block;
  font-size: 12px;
}

.used_team_member ul li:first-child {
  margin-left: 0;
}

.used_team_member ul li:last-child {
  margin-left: 0;
}

.save_tearm_content {
  position: relative;
}

.save_tearm_content .custome_table {
  height: calc(100vh - 223px);
  overflow-y: auto;
  padding-bottom: 85px;
  position: relative;
}

.recive_point_card {
  background: #0b1823;
  border-radius: 8px;
  padding: 10px 10px 15px 10px;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.recive_point_card_wrap .col {
  padding: 0 7px;
}

.recive_point_card_wrap .row {
  margin: 0 -7px;
}

.point-tag {
  position: absolute;
  top: 5px;
  right: 4px;
  width: 20px;
  height: 20px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #fff;
}

.bg-green {
  background: #5da40b;
}

.bg-orange {
  background: #fb6d31;
}

.recive_point_card figcaption {
  flex-grow: 1;
  text-align: center;
  padding: 0 10px;
  font-size: 14px;
  color: #242424;
  line-height: 1.2;
  padding-top: 18px;
}

.recive_point_card figure {
  margin: 0;
  flex: 0 0 64px;
  max-width: 64px;
  height: 48px;
  display: flex;
  align-items: center;
}

.recive_point_card figcaption span {
  display: block;
  padding-bottom: 5px;
  color: #fff;
}
.bg-theme-green {
  background-color: #11ffbd;
  color: #000;
}
.bg-yellow {
  background-color: #f7b32b;
  color: #000;
}
.recive_point_card figcaption .text-orange {
  color: #11ffbd;
}

.save-team-table .c-tagtable,
.save-team-table .vc-tagtable {
  width: 22px;
  height: 22px;
  margin-left: 10px;
  border: 1px solid #dcdcdc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 100px;
  color: #000;
}

.recive_point_card_wrap {
  padding: 15px 24px 15px 24px;
}

.save-team-table td .c-tagtable.active {
  background: #f7b32b;
  color: #fff;
}

.c-tagtable.cursor-pointer.bg-orange {
  background-color: #11ffbd;
}

.save-team-table td .vc-tagtable.active {
  background: #fb6d31;
  color: #fff;
}

.all_contest_tabs_style .my-tearms-tabs-custome {
  padding: 0;
  background: #0b1823;
}

.all_contest_tabs_style .my-tearms-tabs-custome button {
  padding: 12px 8px;
  font-size: 12px;
  font-weight: bold;
  color: #5b6f84;
}

.team-contest-wrapper {
  margin-bottom: 10px;
}

.allCotest_tabScroll {
  height: calc(100vh - 358px);
  overflow-y: auto;
}
.leaderboard_team_list li figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.two_btn_action_bottom .btn {
  width: calc(50% - 10px);
  margin: 0 5px;
}

.two_btn_action_bottom {
  margin: 0 -5px;
}

.two_btn_action_bottom .btn span {
  margin-right: 7px;
}

.winning_tabs .nav-item {
  flex-grow: 1;
}

.winning_tabs .nav-item button {
  width: 100%;
  color: #000;
}

.winning_card_outer {
  padding: 0 24px 5px 24px;
}

.winning_tabs .my-tearms-tabs-custome {
  padding: 0;
}

.winning_tabs .my-tearms-tabs-custome {
  padding: 0;
}

.rank_data {
  height: calc(100vh - 218px);
  background: #0b1823;
  font-size: 14px;
}

.rank_data_head h5 {
  font-size: 14px;
  padding: 11px 24px;
  margin-bottom: 0;
  border-bottom: 1px solid #5b6f84;
  color: #b4e1ff;
}

.rank_data li .rank {
  color: #b2b2b2;
  font-size: 14px;
}

.rank_data li {
  padding: 8px 24px;
  border-bottom: 1px solid #5b6f84;
}

.rank_data li:last-child {
  border-bottom: none;
}

.rank_data li .winnings-value {
  color: #b2b2b2;
  font-size: 14px;
}

.winning_tabs .tab-content {
  height: calc(100% - 64px);
  overflow-y: auto;
}

.winning_tabs {
  height: calc(100vh - 324px);
  background: #fff;
}

.rank_data li .winnings-value {
  color: #b2b2b2;
}

.leaderboard_download {
  padding: 12px 24px;
  background-color: #344452;
  border-bottom: 1px solid #b2b2b2;
}

.leaderboard_team_list h5 {
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 10px 24px;
  border-bottom: 1px solid #b2b2b2;
}

.leaderboard_team_list ul {
  padding: 0;
}

.leaderboard_team_list ul li {
  display: flex;
  align-items: center;
}

.leaderboard_team_list li figure {
  margin: 0;
  margin-right: 10px;
  width: 44px;
  height: 44px;
  border-radius: 100px;
  border: 1px solid #c31235;
  overflow: hidden;
}

.leaderboard_team_list ul li strong {
  color: #fff;
  font-size: 14px;
  padding-right: 10px;
}

.team_tag {
  background: #ffefe8;
  color: #fb6d31;
  padding: 0;
  width: 18px;
  height: 18px;
  text-align: center;
  font-size: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.leaderboard_team_list ul li.active {
  background: #344452;
  border-bottom: 2px solid #c4c4c1;
}

.leaderboard_team_list ul li.active .team_tag {
  background: #50606e;
  color: #f7b32b;
}

/* 4-sm */
.remain_money {
  border-radius: 20px;
  background-color: #f7b32b;
  padding: 8px 10px;
  color: #0b1823;
  font-weight: bold;
  font-size: 16px;
}

.remain_money:hover {
  color: #0b1823;
}

.remain_money img {
  vertical-align: middle;
  position: relative;
  top: -2px;
}

.notification span {
  top: -7px;
  right: -9px;
  color: #fff;
  background-color: red;
  font-size: 8px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 3px solid #01023c;
}

.profile_pic {
  overflow: hidden;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  margin-right: 5px;
}

.sidmenu_icon {
  position: absolute;
  left: -8px;
  /* bottom: -10px; */
}

.border-gold {
  border: 1px solid #efd217;
  border-radius: 12px;
  overflow: hidden;
}

.border-gold img {
  width: 100%;
}

.blue-banner h4 {
  position: absolute;
  bottom: 0;

  font-size: 24px;
  color: #fff;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(45deg, #12799c, #0e1334);
  width: 100%;
  padding: 10px;
  margin: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.offcanvas-start {
  background-color: transparent;
}

.profile_offcanvas .offcanvas-body {
  position: relative;
  border-radius: 0 19px 0 0;
  background-color: #13212d;
}

.edit_icon {
  bottom: 0px;
  left: -5px;
  z-index: 9;
}

.verified {
  background: #fff;
  display: inline-block;
  border-radius: 19px;
  padding: 2px 10px;
  border: 2px solid #35b80f;
  color: #35b80f;
  font-size: 12px;
}

.avail_balance h5 {
  font-size: 14px;
  color: #f7b32b;
  font-weight: bold;
}

.avail_balance {
  padding: 10px 20px;
  background-color: #263642;
  margin-top: -60px;
}

.sidebar .sidebar_content {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #5b6f84;
  margin: 0;
  padding: 10px 0;
}

.sidebar_content h5 {
  font-size: 14px;
  color: #fff;
  margin: 0;

  width: 100%;
  padding: 10px;
}

.sidebar .btn {
  background: none;
  border: 0;
  color: #000;
  font-size: 14px;
  color: #003a58;
  margin: 0;

  text-align: left;
  width: 100%;
  padding: 8px 14px;
}

.sidebar .btn:hover {
  background-color: transparent;
}

.sidebar .dropdown-menu {
  position: static !important;
  transform: translate3d(0px, 0px, 0px) !important;
  padding: 0 24px 20px 46px;
}

.sidebar .dropdown-toggle::after {
  display: none;
}

.sidebar .dropdown-toggle .angle {
  margin-left: auto;
}

.sidebar .dropdown-toggle .angle {
  margin-left: auto;
  transform: rotate(180deg);
}

.sidebar .dropdown.show .dropdown-toggle .angle {
  transform: rotate(0deg);
}

.canvas_header {
  background: url("../../images/top_headerBG.png");
  padding-bottom: 83px;
}
.profile_pic img {
  object-fit: cover !important;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.sidebar_content:hover h5,
.app_version a:hover {
  color: #11ffbd;
}

.app_version h5 {
  font-size: 14px;
  color: #f7b32b;
  line-height: 1.5;
}
.sidebar .btn,
.sidebar .btn:hover {
  background: transparent;
}
.app_version a {
  color: #11ffbd;
  font-weight: 600;
}

.app_version h5 span {
  font-size: 14px;
  color: #b4e1ff;
}

.sidebar .dropdown-menu .dropdown-item {
  color: #fff;
  font-size: 14px;
  padding: 8px 10px;
}
.sidebar .dropdown-menu {
  background: transparent;
}

.verify_kyc {
  background-color: #fff !important;
  color: #fb6d31 !important;
  padding: 2px 10px !important;
  font-size: 12px !important;
  border-radius: 19px !important;
  border: 2px solid #fb6d31;
}

.verify_kyc:hover {
  background: #fff !important;
  color: #000 !important;
  border-color: #11ffbd !important;
}

.verify_kyc:hover img {
  filter: grayscale(100);
}

.middle_panel .bottom_checklist {
  padding: 0;
  margin: 0;
  display: flex;
  position: fixed;
  max-width: 429px;
  bottom: 22px;
  width: 100%;
}

.middle_panel .bottom_checklist li {
  width: 33.33%;
  font-size: 14px;
  padding: 0 10px;
}

/* 4-sm */

.bottom_menu {
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 430px;
  z-index: 9;
}

.menu_tabs_fx {
  padding: 10px 10px 24px 10px;
  background: linear-gradient(176deg, #264c55, #0f2735);
}

.bottom_menu ul {
  display: flex;
  margin: 0;
  padding: 0;
  z-index: 9;
  position: relative;
  align-items: flex-end;
}

.bottom_menu ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  flex: 1;
  max-width:20%;
}

.bottom_menu ul li a {
  display: flex;
  font-size: 12px;
  font-weight: 500;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 0 6px;
}

.bottom_menu ul li img::not(.home_menu img) {
  display: block;
  margin: 0 auto 5px;
  max-height: 24px;
}

.bottom_menu ul li.home_menu {
  margin-top: -25px;
}

.full_page_scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 171px);
}
.full_page_scroll::-webkit-scrollbar {
  width: 0px;
}

.height_clc_282 {
  height: calc(100vh - 282px);
}

.contest-user-list .player-list-data {
  flex: 1;
  max-width: 180px;
  padding-right: 15px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #323e77;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: #323e77;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

.text-yellow,
.text-yellow a,
.text-yellow a:hover {
  color: #f7b32b;
}
.text-light-blue a,
.text-light-blue a:hover {
  color: #b4e1ff;
}
.text-purple a,
.text-purple a:hover {
  color: #d68fd6;
}
.text-light-red a,
.text-light-red a:hover {
  color: #ef5b5b;
}
.bottom-home a,
.bottom-home a:hover {
  color: #00e59f;
}
.Withdrawable {
  font-size: 18px;
}
.bottom_menu ul li a.active img.active,
.bottom_menu ul li a:hover img.active {
  display: block;
}

.bg-transparent {
  background: none !important;
}

.header_tab_scroll {
  height: calc(100vh - 237px);
  overflow-y: auto;
}

.panel_foot h6 {
  font-size: 12px;
}

.panel_foot h6 span {
  color: #3ebc2c;
}

.match_live {
  color: #ff002b;
  font-size: 12px;
  border: none;
  font-weight: 600;
}

.match_live span {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #ff002b;
  display: inline-block;
  position: relative;
  border: 2px solid #fff;
}

.match_live span:after {
  position: absolute;
  content: "";
  /* background-color: #6ec631; */
  width: 14px;
  height: 14px;
  border-radius: 100%;
  z-index: -1;
  top: -3px;
  left: -3px;
}

.text-green {
  color: #6ec631;

  font-size: 14px;
}

/* ms-7-nov */

.offcanvas-common {
  max-width: 428px;
  margin: 0 auto;
  border-radius: 32px 32px 0 0;
  height: auto !important;
}
.inner-text-white *,
.text_content_page p {
  color: #ddd;
}

.static_page h2,
.static_page h2 span {
  font-size: 18px;
  color: #fff !important;
}
.text_content_page h3 {
  color: #fff;
}

.rewart_point_page h2 {
  color: #11ffbd;
}
.rewards_date {
  color: #b4e1ff;
}
.rewart_point_page h2 span {
  font-weight: bold;
  color: #fff;
}
.static_page h3 {
  font-size: 20px;
  color: #fff;
}

.reminder_switch .form-check-label {
  float: left;
}

.offcanvas-common .offcanvas-header {
  border-bottom: 1px solid #dfdfdf;
  padding: 20px 24px 12px 24px;
}

.filter-heading {
  flex: 0 0 57%;
  max-width: 57%;
  color: #000;
}

.offcanvas-common .btn-close {
  color: #242424;
  opacity: 1;
}

.filter-canvas .offcanvas-title {
  font-size: 18px;
  color: #000;
}

.filter-heading .reset-btn {
  font-size: 16px;
  color: #11ffbd;
}

.resetFilters:hover {
  color: #11ffbd !important;
}
.filter-canvas {
  background: #344452;
  border-radius: 32px 32px 0 0;
}

.filter-canvas .offcanvas-body {
  background: transparent;
  border-radius: 0 !important;
}

.offcanvas-common.filter-canvas .btn-close,
.dark_theme_modal .btn-close,
.dark_theme_canvas .btn-close {
  background-image: url("../../images/white_crose.svg");
}

.filter-entry ul li {
  display: inline-block;
  width: calc(50% - 20px);
  margin-bottom: 23px;
  text-align: center;
  position: relative;
  margin: 0px 10px 8px;
}

.filter-entry ul li .form-check {
  padding: 0px;
  position: relative;
}

.filter-entry ul li .form-check input[type="radio"] {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.filter-entry ul li .form-check label {
  background-color: #607180;
  display: block;
  padding: 10px 4px;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.filter-entry ul li .form-check input[type="radio"]:checked + label {
  background: #11ffbd;
  color: #000;
}

.filter-entry h4 {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  padding: 11px 0px;
  padding-left: 10px;
}

.bottom-menu-sec {
  padding: 15px 22px;
  background: linear-gradient(45deg, #0d214e, #023073);
}

.price-pool-header li {
  display: inline-block;
  color: #fff;

  font-size: 14px;
  flex: 1;
}

.price-pool-header ul {
  display: flex;
  justify-content: space-between;
  padding: 10px 18px;
  border-bottom: 1px solid #d2d2d2;
  margin: 0;
}

.pool-team tbody tr td {
  background-color: #344452;
  border-color: #000000;
}

.pool-team tr td {
  padding: 10px 14px;
  color: #f7b32b;
}

.pool-team tbody tr td span {
  background: #13212d;
  border-radius: 2px;
  color: #f7b32b;
  padding: 1px 3px;
  font-size: 12px;
  margin-left: 7px;
}

.mega-pool h5 {
  font-size: 16px;
  color: #11ffbd;
  font-weight: bold;
  margin: 0;
  flex: 1;
}

.live-status {
  color: #fff;
  position: relative;
}

.live-status::before {
  content: "";
  position: absolute;
  left: -16px;
  width: 10px;
  height: 10px;
  background: #35b80f;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.live-status-soccer {
  color: red;
  font-weight: 600;
  position: relative;
}

.live-status-soccer::before {
  content: "";
  position: absolute;
  left: -16px;
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.team-over-score {
  font-size: 13px !important;
  line-height: 23px;
  font-weight: bold;
}
.contest-user-list .player-list-data > .div {
  flex: 0 0 50%;
  max-width: 50%;
}

.contest-user-list h5 {
  font-size: 14px;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.player-list-data {
  flex: 0 0 33%;
  max-width: 33%;
}

.contest-user-list {
  padding: 14px 24px 10px;
}

.contest-user-list h5:last-child {
  margin: 0;
}

.scorecard .accordion-button {
  padding: 0px;
  color: #fff;
  background-color: transparent;
  border-bottom: none;
  padding-bottom: 10px;
  box-shadow: none;
}

.scorecard.scorestate .accordion-collapse {
  background: #f8f8f8;
}

.scorecard .score-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  color: #fff;
}
.scorecard .accordion-item thead td {
  color: #f7b32b;
}

.score-over-sec {
  flex: 0 0 35%;
  max-width: 35%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -20px;
}

.scorecard .accordion-button::after {
  filter: none;
  background-image: url(../../images/arrowdn.svg);
  background-size: 12px;
  background-position: center;
}

.score-table tbody tr td span {
  color: #9ca7af;
  font-size: 12px;
}

.score-table tbody tr td:first-child,
.score-table thead tr td {
  padding-left: 0px;
}

.score-table thead tr td {
  border: none;
}

.score-table thead tr td,
.score-table tbody tr td {
  text-align: right;
}

.score-table tbody tr:last-of-type td {
  border: none;
}

.scorecard .accordion-item {
  padding: 17px 24px 14px;
  border-color: #dfdfdf;
}

.scorecard .accordion-item {
  padding-top: 17px;
  border-bottom: 1px solid #ddd;
}

/* .point-plyaer .table tr:nth-child(odd) {
  background-color: transparent;
} */

.point-plyaer thead th {
  color: #f7b32b;
  background: #0b1823;
  border-bottom: 1px solid #ddd;
}

.point-plyaer thead th:last-child,
.point-plyaer tbody td:last-child {
  padding-right: 0px;
}

.my-tearms-tabs .point-plyaer {
  height: auto;
}

.scorestate .score-header {
  color: #fff;
}

.live-winning-lead {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #0b1823;
  padding: 10px;
}
.wining_head {
  background-color: #344452;
}

.live-winning-lead .win-score {
  color: #fff;
  padding: 0px 17px;
  text-align: center;
  line-height: 23px;
}

.live-winning-lead .win-score span {
  display: block;
  color: #f7b32b;
}

.live-leader-list ul li div {
  flex: 0 0 20%;
  max-width: 20%;
  color: #fff;
}

.live-leader-list ul li div:first-child {
  flex: 1;
  max-width: 100%;
}

.live-leader-list .win-score {
  flex: 0 0 25%;
  max-width: 25%;
}

.live-leader-list .win-score:first-child {
  flex: 1;
  max-width: 100%;
  text-align: left;
  color: #f7b32b;
}

/* ms-7-nov */

/* 7-sm */
.get_verify h2 {
  color: #fff;
  font-size: 23px;

  margin: 0;
}

.get_verify {
  margin-top: auto;
  margin-bottom: auto;
  max-width: 250px;
}

.get_verify p {
  font-size: 13px;
  color: #fff;

  margin: 0;
}

.kyc_banner {
  background: linear-gradient(45deg, #00e59f, #3478de);
  border-radius: 19px;
  padding: 10px 17px;
  margin-top: 32px;
}

.kyc_card {
  border-radius: 15px;
  margin-top: 12px;
}

.kyc_icon {
  width: 30px;
}

.kyc_contact {
  width: calc(100% - 30px);
  padding: 20px 0;
}

.kyc_contact h4 {
  font-size: 16px;
  color: #fff;
}
.kyc_icon img {
  filter: brightness(10);
}

.kyc_contact h6 {
  font-size: 11px;

  white-space: nowrap;
}

.kyc_contact h4 span {
  font-size: 14px;
  color: #b2b2b2;
}

.verifiy_green {
  color: #4ba707;
  font-size: 11px;
  background-color: #f1f8f7;
  border-radius: 7px;
  padding: 8px 22px;
}

.verifiy_btn {
  font-size: 11px;
  color: #fff;
  border-radius: 7px;
  border: 1px solid #e6e6e6;
  padding: 6px 22px;
  cursor: pointer;
}

.verifiy_btn:hover {
  background-color: #11ffbd;
  color: #000;
}

.review_btn {
  color: #fb6d31;
  border-radius: 7px;
  border: 1px solid #fbb831;
  padding: 6px 22px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: "Upload PAN PROOF";
  display: inline-block;
  background: #f5fafe url(../../images/upload.svg) no-repeat 70px center;
  border: 1px dashed #e6e6e6;
  border-radius: 3px;
  padding: 16px 8px;
  cursor: pointer;
  text-shadow: 1px 1px #fff;

  font-size: 16px;
  width: 100%;
  text-align: center;
  color: #048cfb;
}

.custom-file-input-bank::before {
  content: "Upload PROOF";
  display: inline-block;
  background: #f5fafe url(../../images/upload.svg) no-repeat 70px center;
  border: 1px dashed #e6e6e6;
  border-radius: 3px;
  padding: 16px 8px;
  cursor: pointer;
  text-shadow: 1px 1px #fff;

  font-size: 16px;
  width: 100%;
  text-align: center;
  color: #048cfb;
}

.custom-file-input:hover::before {
  border-color: #048cfb;
}

.upload_pan .form-control {
  padding: 0;
}

.kyc_card .form-control {
  /* border: 1px solid #e6e6e6 !important; 
  padding-top: 11px !important;
  */
  border-radius: 8px;
  padding: 0px 22px;
  line-height: 1;
  height: 48px;
}
.create-float-contest a:hover {
  color: #000;
}
.important_text {
  background-color: #f6efd6;
  border-radius: 15px;
  border: 1px solid #e6d18b;
  padding: 12px 16px;
}

.important_text h6 {
  font-size: 16px;

  color: #cf9f00;
}

.important_text p {
  font-size: 12px;

  color: #9f9f9f;
  margin: 0;
}

.form-control:focus {
  box-shadow: none;
  border-color: #5b6f84;
  background-color: #13212d;
  color: #fff;
}

.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
  transform: translateY(-24px) translateX(11px);
  background: #fff;
  height: auto;
  top: 0;
  width: auto;
}

.dark-theme-form.form-floating > .form-control-plaintext ~ label,
.dark-theme-form.form-floating > .form-control:focus ~ label,
.dark-theme-form.form-floating > .form-control:not(:placeholder-shown) ~ label,
.dark-theme-form.form-floating > .form-select ~ label {
  background: #0b1823;
}

.form-control.dark-theme-form {
  background: transparent;
  border: 1px solid #5b6f84;
  color: #fff;
  padding: 15px;
}

.light_border td {
  border-color: #dfdfdf0d;
}
.form-floating > label {
  padding: 0 10px;
  top: 11px;
  font-weight: normal;
}
.dark-theme-form .form-control {
  background: transparent;
  border: 1px solid #5b6f84;
  color: #fff;
}

.dark-theme-form .form-control option {
  color: #000;
}

.my_profile .dark-theme-form .form-control {
  margin-bottom: 40px;
}

.dark-theme-form .form-control label {
  background: #0b1823;
}

.dark-theme-form.form-floating > .form-control:focus ~ label {
  background: #0b1823;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label {
  top: 13px;
}
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.1rem;
}

.verify_bottom {
  background: linear-gradient(45deg, #0d214e, #011034);
  margin: 0 -23px;
  padding: 15px 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 430px;
}

.verify_bottom .btn-green:hover {
  color: #fff;
}

.verify_bank .custom-file-input::before {
  content: "Upload BANK PROOF";
  background: #f5fafe url(../../images/upload.svg) no-repeat 68px center;
}

.form-select:focus {
  border-color: #eee;
  outline: 0;
  box-shadow: none;
}

.congrats_tick {
  padding-top: 138px;
}

.congratulations h3 {
  font-size: 32px;
  color: #000;
}

.congratulations p {
  font-size: 18px;
  color: #000;

  margin: 18px;
  line-height: 18px;
}

.congratulations .btn-green:hover {
  color: #fff;
}

.app_version span {
  font-size: 12px;
  color: #2b2b2b;
}

.app_version {
  border-top: 1px solid #5b6f84;
  padding-top: 24px;
}

.sidebar_content .dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
}

.sidebar .dropdown-item:hover {
  background-color: transparent;
  color: #11ffbd;
}

.otp_page form .form-control.correct {
  border: 1px solid #048cfb;
}

.referral-link:hover a {
  color: #f13b5b;
}

.profile_edit .profile_img {
  width: 83px;
  height: 83px;
  border-radius: 100%;
  object-fit: cover;
}

.upload_pic {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: -20px;
}

.upload_pic input[type="file"] {
  opacity: 0;
  width: 100%;
}

.canvas_header .upload_pic {
  left: 0;
  right: auto;
}

.back_arrow {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  font-family: "Kanit", sans-serif;
}

/* 7-sm */
.info_icon {
  top: 0;
  right: 10px;
}

.wallet_card {
  border-radius: 10px;
  padding: 19px 27px;
}

.money_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  border-radius: 100%;
  background-color: #344452;
  margin: 0 auto 10px auto;
}

.wallet_card h5 {
  font-size: 16px;
  font-weight: bold;
  color: #f7b32b;
}

.wallet_card h6 {
  padding-top: 5px;
  font-size: 14px;
  color: #ffffff;
}

.withdraw_btn {
  color: #cf9f00;
  background-color: #f6efd6;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  padding: 18px;
  font-size: 12px;
}

.withdraw_btn:hover {
  color: #cf9f00;
  text-decoration: underline;
}

.know_more h4 {
  font-size: 14px;
  color: #afafaf;
}

.close_icon img {
  max-width: 50px;
}

.transaction_link {
  font-size: 16px;
  color: #3b3b3b;
}

.wallet_header h3 {
  font-size: 22px;
  font-weight: bold;
}

.wallet_header h4 {
  font-size: 16px;
  color: #fff;
}

.offer_ad {
  background-color: #01030c;
}

.offer_ad h4 {
  font-size: 12px;
  background-color: #11ffbd;
  padding: 0 12px;
  color: #000 !important;
}

.offer_ad h4::after {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #11ffbd;
  content: "";
  position: absolute;
  right: -20px;
}

.offer_ad a {
  color: #11ffbd !important;
  font-size: 12px;
}

.offer_ad a:hover {
  text-decoration: underline;
}

.offer_radio .custom_radio {
  display: block;
  position: relative;
  cursor: pointer;
  line-height: 22px;
  position: relative;
  padding: 20px 10px;
  margin: 0;
}

.offer_radio .custom_radio input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0px 0px;
  z-index: 999;
}

.offer_radio .custom_radio .radio_indicator {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background: #344452;
  color: #fff;
  border: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
}

.offer_radio .custom_radio input:checked + .info + .radio_indicator:before {
  width: 20px;
  height: 20px;
  background: url("../../images/green-check.png") no-repeat;
  position: absolute;
  right: 0px;
  top: 6px;
  content: "";
}

.offer_radio h5 {
  color: #fff;
  margin: 0;
  font-size: 12px;
  text-align: center;
}

.offer_radio h5 span {
  display: block;

  font-size: 16px;
}

.and_tag {
  font-size: 12px;
  color: #4e4e4e;
  background-color: #e6e6e6;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin: 10px auto;
}

.offer_radio {
  width: calc(25% - 10px);
  margin: 0 5px;
}

.add_cash h4 {
  color: #a4a4a4;
  font-size: 14px;
}

.offer_radio .custom_radio .info {
  z-index: 2;
  position: relative;
}
.contest_card {
  background: #344452;
}

.offer_radio .custom_radio input:checked + .info + .radio_indicator {
  background-color: #11ffbd;
}

.offer_radio .custom_radio input:checked + .info h5 {
  color: #000;
}

.offer_radio .custom_radio input:checked + .info .and_tag {
  background-color: #193f8a;
  color: #fff;
}

.offer_ad p {
  padding: 0 6px 0 25px;
  max-width: 252px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cash_box h3 {
  font-size: 16px;
  color: #fff;
}

.active_offer .slick-track .slick-slide > div {
  padding: 0 6px;
  width: 380px !important;
}

/* 9 sm */
.custom_check input:checked + .check_indicator:before {
  width: 7px;
  height: 12px;
  position: absolute;
  left: 5px;
  top: 0px;
  border: solid #00cc00;
  border-width: 0px 2px 2px 0px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
}

.discount_main h3 {
  font-size: 18px;
  color: #fff;
}

.discount_main h3 span {
  font-size: 14px;
}

.discount_main p {
  font-size: 12px;
  margin-bottom: 0px;
  color: #fff;
}

.discount_icon {
  /* background-color: #dbe3e7; */
  /* padding: 20px; */
  /* border-radius: 100%; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  margin-right: 10px;
  width: 60px;
  height: 60px;
}

.discount_sec {
  align-items: center;
}

.discount-inner-sec {
  width: calc(100% - 90px);
}

.discount_icon img {
  width: 86px;
}

.discount_main .text-success {
  color: #d68fd6 !important;
  font-size: 14px;
  border: none;
}

.active_offer .slick-list {
  padding: 0 !important;
}

.offer_code .code_num {
  width: 50%;
  text-align: center;
  font-size: 14px;
  color: #f7b32b;
  background-color: #0b1823;
  padding: 5px;
}

.offer_code a {
  width: 50%;
  text-align: center;
  font-size: 14px;
  color: #fff;
  background-color: #0b1823;
  padding: 5px;
}

.addCash_scroll {
  height: calc(100% - 140px);
  overflow-y: auto;
}

.payment_method h5 {
  font-size: 18px;
}

.upi_apps a {
  color: #3b3b3b;
}

.upi_apps h6 {
  margin: 0;
  text-align: center;
}

.bg-dark-second {
  background-color: #13212d;
}

.wallets h6 {
  color: #3b3b3b;
}

.wallets a {
  color: #048cfb;
  font-size: 16px;
}

.wallets a:hover {
  text-decoration: underline;
}

.cards .text-dark {
  color: #3b3b3b;
}

.my_transaction .dropdown-menu {
  background-color: #dfecf6 !important;
  border-radius: 8px;
  inset: 7px auto auto 0px !important;
  box-shadow: 0px 3px 4px 2px rgb(0 0 0 / 10%);
  width: 210px;
  border: 0;
}

.my_transaction .dropdown-menu h6 {
  font-size: 14px;
  color: #000;
}

.my_transaction .dropdown-menu input {
  border: 1px solid #707070;
  background-color: #fff;
  border-radius: 5px;
}

.transaction_main h6 {
  margin-bottom: 0;
  font-size: 14px;
  color: #b2b2b2;
}
.withdraw_scroll .transaction-box {
  background: #13212d;
  border-color: #dddddd5c !important;
}

.transaction_main h6 span {
  font-size: 14px;
  line-height: 18px;
  color: #b2b2b2;
  font-weight: 600;
}
.transaction_main h6 span.d-block {
  color: #fff;
  font-weight: 600;
}
.transaction_main .date-format {
  color: #fff;
  font-weight: 600;
}
.transaction_main h5 {
  font-size: 18px;
}

.transaction_main .text-danger {
  color: #ef5b5b !important;
}

.my_transaction .custom_check input,
.my_transaction .custom_check .check_indicator {
  right: 0;
  left: auto;
}

.my_transaction .custom_check {
  padding-left: 0;
}

.transaction_scroll {
  height: calc(100vh - 236px);
  overflow-y: auto;
}

.my_transaction .custom_check input:checked + .check_indicator {
  background: #13212d;
  border-color: #13212d;
  border-radius: 3px;
}

.my_transaction .custom_check input:checked + .check_indicator:before {
  border-color: #fff;
}

.my_profile .profile_edit {
  max-width: 116px;
  border: 2px solid #fff;
  border-radius: 100%;
  height: 116px;
  margin: 24px auto 36px auto;
  top: 0px;
}

.my_profile .profile_edit .profile_img {
  width: 112px;
  height: 112px;
  border-radius: 100%;
}

.my_profile .custom_radio {
  display: block;
  position: relative;
  cursor: pointer;
  line-height: 22px;
  position: relative;
  padding-left: 0;
  margin: 0;
  height: 100%;
  width: 140px;
}
.profile_form {
  height: 100%;
}

.profile_form .onlyHeader_scrol {
  height: 100%;
}

.my_profile .custom_radio .radio_indicator {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #cccccc;
  position: absolute;
  top: 0px;
  padding: 20px;
  left: 0px;
}

.my_profile .custom_radio input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0px 0px;
  z-index: 9;
  padding: 12px 10px;
}

.male_radio {
  z-index: 2;
  position: relative;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5px;
  color: #fff;
  font-size: 16px;
  background-color: #13212d;
  border: 1px solid #5b6f84;
  border-radius: 5px;
  height: 100%;
}

.team_preview {
  /* position: absolute; */
  position: initial;
  top: 0;
  right: -100%;
  width: 100%;
  max-width: 428px;
  height: calc(100% - 33px);
  padding-top: 25px;
  background: linear-gradient(0deg, #000024, #01064b);
}

.cricket_ground {
  background: url("../../images/cricket_ground.png") no-repeat center top;
  background-size: cover;
  min-height: calc(100% - 114px);
  border-radius: 22px 22px 0 0;
  padding: 12px 40px;
}

.cricket_ground h6 {
  background-color: #05481f;
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;

  display: inline-block;
  padding: 6px 15px;
  border-radius: 20px;
}

.cricket_ground .pPic {
  width: 54px;
  height: 54px;
  border-radius: 100%;
  border: 2px solid #048cfb;
  margin: 0 auto -10px;
  display: flex;
  align-items: flex-end;
}
.cricket_ground .player {
  padding: 5px;
  position: relative;
}

.cricket_ground .pPic img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: block;
  margin: auto;
}

.cricket_ground .pName {
  display: inline-block;
  background: #13212d;
  font-size: 12px;
  color: #fff;
  padding: 4px 12px 4px 20px;
  border-radius: 30px;
  z-index: 2;
  margin-bottom: 3px;
  position: relative;
  z-index: 3;
}

.cricket_ground .player span {
  font-size: 10px;
  color: #fff;
  display: block;
}

.AllRounder {
  margin-top: 24px;
}

.bowler {
  margin-top: 32px;
}

.cricket_ground .orange .pName {
  background: #cc5c09;
}

.cricket_ground .orange .pPic {
  border-color: #cc5c09;
}

.team_preview .top_header {
  padding: 0;
  background: none;
}

.inner_slider .slick-dots li button {
  width: 8px;
  height: 8px;
  padding: 0;
}

span.captain_badge {
  top: 10px;
  left: 10px;
  font-size: 16px;
  width: 20px;
  height: 20px;
  background: #000;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  padding: 2px 0;
  border: 1px solid #ffff;
}

span.offline_player {
  background-color: #ff0000;
  top: 0;
  right: 2px;
  font-size: 16px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  border: 1px solid #ffff;
}

.teamVS {
  font-size: 13px;
  color: #fff;
}

.teamVS span {
  color: #fff;
  font-size: 13px;

  border-radius: 6px;
  padding: 3px 8px;
  margin: 0 5px;
}

.bg-orange {
  background-color: #fb6d31;
}

.bg-blue {
  background-color: #048cfb;
}

.top_header h6 {
  color: #fff;
  margin: 0;

  font-size: 14px;
}

.refer_friend h2 {
  font-size: 20px;
  color: #fff;
}

.refer_friend p {
  color: #fff;

  font-size: 14px;
}

.refer_coupon {
  background: #13212d;
  border: 2px dashed #fff;
  border-radius: 18px;
  padding: 15px;
}

.refer_coupon p span {
  display: block;
  font-size: 18px;
  text-transform: uppercase;
}

.refer_coupon p {
  padding: 0 56px;
  border-right: 1px solid #fff;
  font-size: 16px;
}

.refer_coupon a {
  color: #fff;

  font-size: 15px;
  padding-right: 10px;
}

.faq {
  height: calc(100% - 689px);
  padding: 35px 24px;
  border-radius: 35px 35px 0 0;
  overflow-y: auto;
}

.faq h3 {
  color: #000000;
  font-size: 18px;

  margin-bottom: 20px;
}

.faq button {
  color: #000000;
  font-size: 14px;

  padding: 15px 0;
}

.faq .accordion-item {
  border: 0 !important;
}

.faq .accordion-button:not(.collapsed) {
  background: none;
  color: #000000;
  box-shadow: none;
}

.copy_code,
.share_code h5 {
  color: #000000;
  font-size: 16px;
}

.copy_code {
  background-color: #344452;
  border-radius: 5px;
  border: none;
  color: #fff;
}
.copy_code a {
  font-size: 14px;
}
.copy_code a img {
  max-height: 12px;
}

.fs-16 {
  font-size: 16px;
}

.rewards h4 span,
.rewards h2 {
  font-size: 30px;
}

.onlyHeader_scroll {
  overflow-y: auto;
  height: calc(100% - 87px);
}

.notifications .icon {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: #f5f5f5;
}

.notifications .text {
  width: calc(100% - 48px);
  padding-left: 12px;
}

.notifications .text h5 {
  font-size: 14px;
}

.notifications .text h5 span {
  color: #9f9e9e;
  font-size: 12px;
  font-weight: 400;
}

.fw-semibold {
}

.howPlay .accordion-item:first-of-type,
.howPlay .accordion-item:last-of-type,
.howPlay .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0px !important;
}

/* ms-new-8 */

.scorestate .table tr td {
  padding: 10px 20px;
}
.dark_theme_canvas .offcanvas-body {
  background: #344452;
}

.dark_theme_canvas .offcanvas-body td {
  color: #ddd;
  border-color: #dfdfdf1f;
}
.dark_theme_canvas .offcanvas-body {
  background: #344452;
}

.dark_theme_canvas .offcanvas-body td {
  color: #ddd;
  border-color: #dfdfdf1f;
}

.dark_theme_canvas {
  background: #344452;
}

.bg-theme-gray {
  background: #24323e;
}

.dark_theme_modal .modal-content {
  background: #344452;
}

.dark_theme_modal .modal-content .modal-title.h4 {
  color: #fff;
}

.dark_theme_modal .modal-content td {
  background: #13212d;
  color: #fff;
}

.dark_theme_modal .modal-content p {
  color: #fff;
}

.dark_theme_canvas {
  background: #344452;
}
.bg-theme-gray {
  background: #24323e;
}

.contest-canvas .offcanvas-title {
  font-size: 24px;
}

.contest-card .form-label {
  font-size: 16px;
  color: #000;
}

.create-float-contest a {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #ececec;
  display: block;
  padding: 1px 25px 14px;
  margin: 0px -15px;
  background-image: url(../../images/angle-icon.png);
  background-repeat: no-repeat;
  background-position: right 20px top 8px;
}

.contest-canvas .btn-green {
  font-size: 16px;
}

.w-border ul li {
  border: none;
}

.user-player figure {
  flex: 0 0 66px;
  max-width: 66px;
  position: relative;
  margin: 0;
}

.user-player figure input[type="file"] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 1;
}

.user-player figcaption h3 {
  font-size: 16px;
  margin: 0;
  color: #fff;

  line-height: 21px;
}

.user-player figcaption {
  padding-left: 10px;
}

.user-player.d-flex {
  padding: 17px 23px 23px;
  align-items: center;
}

.user-player .upload {
  position: absolute;
  top: 0;
  right: 0;
}

.user-player figure div {
  width: 66px;
  height: 66px;
  border-radius: 100%;
  overflow: hidden;
  border: 1px solid #db1f14;
  background: #fff;
}

.user-player figure div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-player figcaption span {
  font-size: 12px;
  color: #fff;
}

.total-player-point .point-sec h2 {
  font-size: 14px;
  color: #000;
  margin: 0;
}

.total-player-point .point-sec {
  background: #11ffbd;
  border-radius: 6px;
  width: 100%;
  margin: 0px 3px;
  padding: 12px;
}

.total-player-point {
  margin-bottom: 17px;
}

.total-player-point .point-sec h2 span {
  display: block;
  font-weight: 500;
  font-size: 14px;
}

.player-info-data td {
  padding: 12px 0px;
  border-color: #b2b2b2;
}

.player-info-data {
  padding: 13px 24px;
}

.player-info-data table thead td {
  margin: 0px -8px;
}

.player-info-data td:last-child {
  text-align: right;
}

.player-info-data .player-thead {
  margin: 0px -24px;
  border-bottom: 1px solid #b2b2b2;
  padding: 13px 25px;
  color: #fff;
}

.player-info-data .player-thead li {
  width: 33.33%;
  font-size: 14px;
  color: #11ffbd;
  font-weight: bold;
}
.match-wise-card .accordion-button {
  background: #344452 !important;
}

.player-info-data .player-thead li:nth-child(2) {
  text-align: right;
}

.player-info-data .player-thead li:nth-child(3) {
  text-align: right;
}

.player-info-data td:first-child {
  width: 57%;
}

.match-heading {
  font-size: 16px;
  color: #fff;
}

.match-wise-player {
  padding: 24px;
}

.match-wise-player .accordion-button {
  color: #000;
  flex-direction: column;
  align-items: normal;
  padding: 10px 17px 9px;
}

.match-wise-player .accordion-button::after {
  position: absolute;
  right: 21px;
  filter: contrast(0.2);
  background-size: 17px;
}

.match-wise-points {
  padding-top: 5px;
  text-align: center;
  border-top: 1px solid #dfdfdf;
  margin-top: 10px;
  color: #fff;
}

.match-wise-points span {
  color: #f7b32b;
  font-size: 12px;
}

.match-wise-points h4 {
  font-size: 14px;
  margin: 0;
}

.match-wise-points > div {
  line-height: 25px;
}

.accordion-button:focus {
  box-shadow: none;
  background: no-repeat;
}

.match-flex span,
.match-flex small,
.match-flex strong {
  color: #fff;
}

.match-wise-player .accordion-button:not(.collapsed) {
  background-color: transparent;
}

.match-wise-card .player-thead {
  background: #5b6f84;
  border: none;
}

.match-wise-card .accordion-item:not(:first-of-type) {
  border-top: 1px solid #dfdfdf;
}

.match-wise-card .accordion-item {
  margin-bottom: 16px;
  border-radius: 10px !important;
  overflow: hidden;
  background-color: #0b1823;
  border: none;
}

.matchPlayerScroll {
  /* height: calc(100vh - 348px); */
  overflow-y: auto;
}

.create-contest.crt-padding {
  padding: 32px 24px;
}

.form-check-input {
  width: 20px;
  height: 20px;
  margin-right: 13px;
  color: #ff0000;
}

.create-contest .form-check-input[type="checkbox"] {
  border-radius: 6px;
}

.create-contest .form-check-input:focus {
  outline: none;
  box-shadow: none;
}

.create-contest .form-check-input ~ label {
  font-size: 14px;
  color: #fff;
  margin-top: 2px;
}

.create-contest .form-check-input:checked {
  background-color: #11ffbd;
  border-color: #11ffbd;
  background-image: url(../../images/check-arrow1.png);
}

.content-flexiable {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 12px;
  position: relative;
}

.content-flexiable h6 {
  color: #242424;
}

.content-flexiable span {
  font-size: 12px;
  display: block;
  color: #707070;
}

.content-flexiable .form-check-input {
  position: absolute;
  top: 17px;
}

.content-flexiable .form-check-input ~ label {
  font-size: 14px;
  color: #242424;
  margin-top: 6px;
  margin-left: 11px;
}

.max-prize-pool.d-flex {
  padding: 17px;
  border-radius: 5px;
  overflow: hidden;
  background-image: url(../../images/max-prize-back.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.max-prize-pool figcaption {
  padding-left: 18px;
}

.max-prize-pool figcaption span {
  font-size: 12px;
  color: #fff;
}

.max-prize-pool figcaption h4 {
  font-size: 16px;
  color: #fff;
  line-height: 30px;
  font-weight: bold;
}

.conentScoller {
  height: calc(100% - 180px);
  overflow-y: auto;
}

/* ms-new-8 */

.gender {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  padding: 12px 60px 16px 19px;
}

.gender h6 {
  margin: 0;
  font-size: 14px;

  color: #a4a4a4;
  background: #fff;
  max-width: 70px;
  text-align: center;
  position: relative;
  top: -21px;
}

.my_profile .custom_radio input:checked + .male_radio + .radio_indicator {
  border-color: #f7b32b;
  background-color: #fdf7f8;
}

.my_profile .custom_radio input:checked + .male_radio {
  color: #f7b32b;
}

.my_profile .custom_radio input:checked + .male_radio img {
  filter: invert(54%) sepia(7%) saturate(5968%) hue-rotate(4deg)
    brightness(107%) contrast(188%);
}

.verify_scroll {
  height: calc(100% - 158px);
  overflow-y: auto;
}

.kyc_card .form-control.custom-file-input {
  padding: 0;
}

.verify_bank .custom-file-input::before {
  text-indent: 48px;
  background-position: 50px center;
}

.withdraw_modal h3 {
  font-size: 32px;
}

.withdraw_modal p {
  max-width: 80%;
  margin: 10px auto;
  font-size: 18px;
  line-height: 22px;
}

.withdraw_modal .modal-content {
  border-radius: 35px;
  max-width: 380px;
  margin: auto;
}

.withdraw_modal .rounded-5 {
  border-radius: 35px !important;
}

.main-wrapper-outer {
  height: 100vh;
}

.my_profile {
  height: 100%;
  padding-bottom: 150px;
  overflow-y: auto;
}

.rewards_date {
  font-size: 12px;
  white-space: nowrap !important;
}

.my_transaction .dropdown-menu .btn {
  border: none;
  padding: 10px;
  font-size: 14px;
  margin: 5px 0;
  width: 100% !important;
}

.my_transaction .dropdown-menu .react-datepicker-wrapper {
  padding: 5px 2px;
}

.right_position_droap_box {
  transform: translate(-37%, 28px) !important;
}

.contest_confirmation_popup {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.contest_confirmation .modal-title {
  font-size: 16px;
  color: #000;
}

.contest_confirmation .modal-dialog {
  max-width: 340px;
}

.contest_confirmation .modal-header {
  padding: 10px 15px;
}

.contest_confirmation_table td {
  padding: 5px 10px;
  background: #fafafa;
}

.contest_confirmation p {
  margin: 0 0 10px 0;
}

.contest_confirmation_table td:last-child {
  text-align: right;
}

.contest_confirmation p {
  margin: 0 0 10px 0;
}

.contest_confirmation_table td:last-child {
  text-align: right;
}

.contest_confirmation_table .total_value td {
  background: #0b1823;
}

.team_head li figure img {
  max-height: 40px;
}

.wicket-rank p {
  color: #f7b32b;
  border-top: 1px solid #ddd;
  padding-top: 2px;
  margin-top: 7px;
}

.transaction_main h5.money_edit {
  white-space: nowrap;
  font-size: 14px;
  color: #11ffbd;
}

.transaction_main h6 .date-format {
  font-weight: 400;
  margin-top: 5px;
  display: block;
}

.remove-kyc-btn {
  height: 48px;
  line-height: 1;
  /* background: #fb6d31 !important;
  border-color: #fb6d31 !important; */
}

.get-friend td {
  border: 1px solid #83838359;
  padding: 4px 10px;
  text-align: left;
}

.panel_foot h5 span {
  color: #11ffbd;
  font-weight: bold;
}

.not-started {
  font-weight: 500;
  color: #11ffbd;
}

.sign-in-check .form-check-input {
  padding: 10px;
  border: 1px solid #11ffbd;
  background: transparent;
  box-shadow: none;
  color: #11ffbd;
  background: #fff;
}
.sign-in-check .form-check-input:checked {
  background: #11ffbd;
}
.team-edit-sec div {
  color: #b3bad1;
}

.team-data-wrapper .second-article figure div {
  border: 2px solid #fb6d31;
}

.kxip h6,
.rcbT h5 {
  font-size: 13px;
}

.contest-detail-sec .kxip {
  width: 100px;
}

.contest-detail-sec .middle-part {
  width: calc(100% - 200px);
}

.contest-detail-sec .middle-part i {
  font-size: 12px;
}

.contest-detail-sec li > div {
  justify-content: center;
}

.leaderboard_download b {
  max-width: 319px;
  color: #fff;
}

.all-time-leaderboard ul li.active {
  background: transparent;
  border-bottom: 1px solid #00000024;
}

.clear_team + modal {
  text-align: center;
}

.clear_team_modal {
  text-align: center;
}

.clear_team_modal .modal-header {
  border: none;
  background-blend-mode: difference;
  justify-content: center;
  padding: 20px 15px 5px 15px;
}

.clear_team_modal .modal-header .btn-close {
  display: none;
}

.clear_team_modal .modal-header img {
  max-height: 42px;
}

.clear_team_modal .modal-dialog {
  max-width: 280px;
}

.clear_team_modal .modal-footer {
  border: none;
  justify-content: center;
}

.clear_team_modal .modal-footer .btn {
  padding: 6px 10px;
  font-size: 13px;
  min-width: 84px;
  border: none;
  font-weight: normal;
  margin: 0 10px;
}

.clear_team_modal .modal-body {
  padding: 10px 24px;
  color: #000;
  font-weight: 500;
}

.clear_team_modal .modal-footer .btn.orange_btn {
  background: #ee6229;
}

.clear_team_modal .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.notification-sec .icon {
  width: 40px;
  height: 40px;
  background: #f6eeee;
  border-radius: 100%;
}

.notification-sec .text {
  width: calc(100% - 40px);
  padding-left: 12px;
}

.notification-sec .text h5 {
  font-size: 14px;
}

.notification-sec .text h5 span {
  font-size: 12px;
  color: #00000087;
  white-space: nowrap;
  padding-left: 10px;
}

.sign-in-check .form-check-input:checked {
  background-image: url(../../images/check-arrow1.png);
}

.terms {
  text-decoration: underline;
}

.not-found-modal {
  width: max-content;
  margin-left: 43%;
  margin-top: 18%;
}

.not-found-modal_1 {
  width: max-content;
  margin-left: 41.5%;
  margin-top: 18%;
}
.my_contest .panel:last-child {
  margin-bottom: 0 !important;
}

.offer-space {
  overflow: hidden;
}
/* .text-start{color:#dcdcdc;} */

.team_count {
  flex: 1;
  max-width: calc(100% - 24px);
}

.minus_point {
  flex: 0 0 20px;
  max-width: 20px;
}

.team-playground-screen {
  height: 100%;
  background: #00262f;
}

.complete-status {
  font-style: normal;
  font-weight: 500;
  color: white;
}

.reword_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.cricket_ground {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.notification-sec .text p {
  font-size: 12px;
  line-height: 1.2;
}
.notification-sec {
  border-bottom-color: #8d8d8d !important;
}

.notification-sec {
  border-bottom-color: #8d8d8d !important;
}

.spinner-border {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  z-index: 999;
  margin-left: -20px;
  color: #00e59f;
}
/* .loader:after {
  content: "";
  position: fixed;
  background: #0000003f;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 9;
}  */

.rewards h4 span {
  line-height: 1;
}

.bank-proof {
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 7px;
}

.transition_value_corner {
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 10px;
}
.transition_list h6 {
  padding-top: 12px;
}

.highlight_tabs .btn:after {
  border: none;
  width: 18px;
  height: 10px;
  background: url(../../images/white_down.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.number_visite {
  background-image: url(../../images/downarrow.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px;
  background-position: right 6px center;
}

.highlight_tabs .dropdown-menu {
  width: 80px !important;
  min-width: initial;
}

.blur-team {
  opacity: 0.5 !important;
  pointer-events: none;
}

.background-grey {
  background-color: grey !important;
}

.contest-new .nav-item {
  width: 50%;
  text-align: center;
}

.contest-new .nav-item button {
  width: 100%;
}

.download_app_icon {
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.login_logo {
  padding-bottom: 36px;
}
.download_app_icon a img {
  max-height: 68px;
}

.bg-dark {
  background-color: #0b1823 !important;
}
.bg-liner-color {
  background: linear-gradient(181deg, #2a5560, #000) !important;
}

.bg-theme-light {
  background-color: #344452;
}
.otp_crose {
  position: absolute;
  right: 24px;
  margin: 20px;
  top: 24px;
}
.help_page .transaction_link {
  margin-bottom: 15px;
  border-radius: 8px;
  background: #11ffbd;
  font-weight: 600;
  color: #000;
}

.icons_tabs .nav-link {
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
  border: none;
  width: 100%;
  text-align: center;
}
.icons_tabs .nav-link img {
  filter: brightness(100);
}

.icons_tabs .nav-link.active img {
  filter: initial;
}
.icons_tabs .nav-link:hover img {
  filter: initial;
}
.icons_tabs .nav-link.active {
  color: #f0b32b;
  background: transparent;
  border: none;
}

.icons_tabs .nav-link:hover {
  border: transparent;
  color: #f0b32b;
}

.icons_tabs {
  display: flex;
  flex-wrap: wrap;
  border: none;
  background: #0b1823;
  margin-bottom: 0;
}
.icons_tabs .nav-link figure {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.icons_tabs .nav-item {
  flex: 1;
}
.bg-blue-dark {
  background-color: #040f19;
}

.scorecard .accordion-item {
  background: transparent;
  border: none;
  border-bottom: 1px solid #dddddd43;
}

.scorecard .accordion-item td {
  color: #fff;
}

.upcoming {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.upcoming img {
  max-width: 200px;
}
.rcbT h5 {
  padding-right: 5px;
  padding-left: 0 !important;
}

.frequent .accordion-item {
}

.frequent-sec1 .accordion-item {
  margin-bottom: 8px;
  border-radius: 8px;
  overflow: hidden;
}

.frequent-sec1 .accordion-item .accordion-button {
  background: #11ffbd;
  border: 1px solid #11ffbd;
  font-weight: 600;
}

.frequent-sec1 .accordion-button:not(.collapsed) {
  color: #000;
}

.frequent-sec1 .accordion-collapse {
  background: #13212d;
}

.frequent-sec1 .accordion-collapse * {
  color: #fff;
}

.frequent-sec1 .accordion-button::after {
  background-image: url("../../images/downarrow.png");
  background-size: 12px;
  background-position: center right;
}

.upload_file {
  position: relative;
  text-align: center;
  padding: 15px;
  color: #327bdc;
  font-weight: 500;
  font-size: 16px;
}

.upload_file input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 9;
  height: 100%;
  cursor: pointer;
}

.my_transaction .dropdown-menu .custom_check.mb-2 {
  color: #222;
  font-weight: 500;
}

.kyc_card > .d-flex {
  border-bottom: 1px solid #eeeeee3c;
}

.modal-content {
  background-color: #153c46;
}
.modal-body {
  color: white;
}

.all_contest_tabs_style .my-tearms-tabs-custome li {
  flex: 1;
  text-align: center;
}

.all_contest_tabs_style .my-tearms-tabs-custome li .nav-link {
  width: 100%;
  text-align: center;
}
.for_cc {
  position: relative;
  max-width: 94px;
  margin: auto;
}

.left_slider {
  max-width: 380px;
  margin: auto;
}

.right_position_droap_box:before {
  left: auto;
  right: 30px;
}

.team-data-wrapper .kcip-team {
  flex: 0 0 40%;
}

.team-data-wrapper .d-flex {
  flex: 1;
  padding-left: 10px;
}

.card_fit {
  background: #b3bad1;
}
.flex-grow-none .my-tearms-tabs-custome li {
  flex: 0 0 auto;
}

.flex-grow-none
  .flex_growing
  li:not(.flex-grow-none .flex_growing li:last-child) {
  flex: 1;
}

.flex-grow-none .flex_growing li .nav-link {
  white-space: nowrap;
}

.flex-grow-none .my-tearms-tabs-custome li button {
  font-size: 13px;
}

.bottom-button-true {
  /* overflow-y: auto;
  height: calc(100% - 87px);
  padding-bottom: 66px; */
}
.bottom-button-false {
  height: calc(100% - 87px);
}

.bottom-button-false .allCotest_tabScroll.bg-dark {
  height: 100%;
}

/* 26-4-2023 */

.text-orange {
  color: #f7b32b !important;
}

.left_slider .slick-slide img {
  width: 100%;
}

.offer_code .text-green {
  color: #00e59f;
}

.teamPreview-edit-icon .normal {
  width: 100px;
  flex: 0 0 100px;
}

.create_contest_point .point-sec {
  border-radius: 0;
  padding: 20px 15px;
  text-align: center;
  margin: 0 -1px;
  border: 1px solid #6b6b6b;
  background: #0b1823;
}

.create_contest_point {
  padding: 0 !important;
}

.create_contest_point .point-sec h2 {
  color: #fff;
}

.create_contest_point .point-sec h2 span {
  margin: 8px 0;
  color: #f7b32b;
}
.text_theme_green {
  color: #11ffbd !important;
}
.text_theme_orange {
  color: #f7b32b;
}

.total.Winners {
  flex: 1;
  max-width: 100%;
}

.total.Winners .offcanvas-title {
  flex: 1;
}

.offcanvas-title small {
  font-size: 13px;
  font-weight: normal;
  margin: 15px 0 0;
  text-align: left;
}

/* ==============league-page-style-2-may-2023 ===============*/

.transfer_content {
  border-top: 1px solid #5b6f84;
  padding-top: 24px;
}

.leaguespacial_head p {
  font-size: 12px;
  color: #b3bad1;
}
.welcome_spacial ul {
  background-color: #b3bad1;
  padding: 15px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  text-align: center;
  color: #000000;
  list-style: none;
}

.welcome_spacial > strong {
  display: block;
  padding: 8px 10px;
  color: #fff;
}

.welcome_spacial ul .league_center {
  flex: 1;
  padding: 0 10px;
  justify-content: center;
}

.welcome_spacial ul li {
  display: flex;
  align-items: center;
}

.welcome_spacial ul li figure {
  max-height: 36px;
  margin: 0;
  flex: 0 0 24px;
  max-width: 24px;
}

.welcome_spacial ul li figcaption {
  padding-left: 10px;
}

.welcome_spacial figcaption strong {
  display: block;
  text-align: left;
  line-height: 1.2;
}

.welcome_spacial figcaption span {
  font-size: 12px;
}

.welcome_spacial {
  overflow: hidden;
  margin-top: -40px;
  position: relative;
}
.league_footer {
  padding: 10px 10px 15px 10px;
}

.leaue_main_section {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 160px);
}
.league_footer ul li:not(.offer_center) {
  flex: 0;
}
.left_booster {
  display: flex;
}

.left_booster p {
  margin: 0;
  font-size: 14px;
  color: #b2b2b2;
  font-weight: 500;
}

.left_booster figcaption {
  padding-left: 15px;
}

.left_booster figcaption strong {
  font-size: 20px;
  color: #fff;
}
.left_booster figcaption strong span {
  font-size: 28px;
  line-height: 1;
}

.select_booster_list {
  border-radius: 15px;
  padding: 18px;
  margin: 0 0 15px 0;
  display: flex;
  position: relative;
}

.booster_circle {
  background-color: #11ffbd;
  width: 51px;
  height: 51px;
  flex: 0 0 51px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #13212d;
  font-weight: bold;
}

.booster_check {
  position: absolute;
  right: 10px;
  top: 10px;
}

.select_booster_list figcaption {
  flex: 1;
  padding-right: 18px;
  padding-left: 15px;
}

.select_booster_list figcaption h5 {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}

.select_booster_list figcaption p {
  font-size: 14px;
  color: #b2b2b2;
  font-weight: 600;
  line-height: 1.4;
}
.theme_light_bg {
  background-color: #5b6f84;
}
.booster_h_left {
  padding-right: 15px;
  flex: 1;
}

.booster_h_left .team_info span {
  white-space: nowrap;
  font-weight: 600;
  color: #fff;
  font-size: 12px;
  width: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.booster_h_left .team_info h5 {
  width: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.booster_h_left .kxip figure,
.booster_h_left .rcbT figure {
  flex: 0 0 40px;
}

.season_league_ctg strong {
  font-size: 20px;
}
.season_league_ctg {
  border-radius: 12px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.season_league_ctg:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: rgb(28 56 65 / 87%);
}

.join_btn {
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 12px 20px;
  width: 124px;
  font-size: 16px;
  background: transparent;
  color: #fff;
}

.daily_contest {
  background-image: url(../../images/daily_bg.png);
  background-size: cover;
}

.season_league_pro {
  background-image: url(../../images/seasonal_pro.png);
  background-size: cover;
}

.season_league_fun {
  background-image: url(../../images/seasonal_fun.png);
  background-size: cover;
}
.z-index-2 {
  z-index: 2;
}
