.offer-space {
    margin-bottom: 7px;
}


.my-offer_scroll {
    max-height: 744px;
    overflow-y: auto;
    margin-bottom: 10px;
    margin-right: 7px;
    cursor: pointer;
}

.cursor {
    cursor: pointer !important;
}

.not_found {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 50%;
    -webkit-font-smoothing: antialiased;
}

.text_white {
    color: white;
}

.completed-status {
    color: #fff;
    position: relative;
}

.completed-status::before {
    content: "";
    position: absolute;
    left: -16px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    top: 50%;
    transform: translateY(-50%);
}

.my_team {
    background: #13212D;
}

.confirm-box {
    display: flex;
    justify-content: center;
    margin-inline: 10px;
}

.normal {
    color: white
}

.ms-10 {
    margin-inline: 10px;
}

.social-media {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

.notificationimageupcoming {
    display: flex;
    margin: 0% 90%;
    /* justify-content: flex-end; */
    /* margin: auto; */

}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.slider.round {
    border-radius: 34px;
}



.resend-button {
    background: transparent;
    border: none;
    color: #11FFBD;
}

.login-tc {
    display: flex !important;
}

.remove-button {
    background: none;
    border: none;
    color: rgb(234, 61, 18)
}

.flex {
    white-space: nowrap !important
}

.restricated_state {
    color: white;
    margin-top: 15px;
    font-weight: 400;
}

.verify {
    background: #13212D;
    color: #11FFBD;
    text-decoration: none;
    justify-content: center;
    max-height: 48px;
    font-size: 16px;
    padding: 15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='checkbox'] {
    accent-color: #11FFBD;
}

.download {
    font-size: larger;
    color: black;
    font-weight: 500;
}

.self-joined {
    background: white;
    color: black;
    font-size: medium;
}

.copy-icon {

    border-radius: 100%;
    height: 43px;
    width: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
}



.logo_image_match {
    height: 40px;
    width: 40px;
    border-radius: 100%;
}

.team_short_name_icon {
    width: 12px;
    height: auto;
    border: none;
    margin-right: 4px !important;
}

.team_option_create_team {
    color: #fff;
    font-size: 12px;
    display: flex;
    justify-content: start;
    align-items: center;
}


.teamPreview-edit-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;

}


.isPlaying-player {
    content: "";
    position: absolute;
    left: -16px;
    width: 10px;
    height: 10px;
    background: #35b80f;
    border-radius: 100%;
    top: 0%;
    left: 50%;
    transform:translateY(-50%) translatex(-50%);
}

.notPlaying-player {
    content: "";
    position: absolute;
    left: -16px;
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 100%;
    top: 0%;
    left: 50%;
    transform:translateY(-50%) translatex(-50%);
}

.fullScreen {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
}

.text-green {
    color: '#11FFBD';
}

.no-bottom-outlet {
    overflow-y: auto;
    height: calc(100% - 0px);
}


.highlight_tabs .dropdown-menu {
    width: 125px !important;
}

.player-circle-red {
    border: 2px solid rgb(251, 22, 35)
}

.refer-table {
    background: white !important;
    color: black !important
}
.date-time-filter-btn{
    display: flex;
}
.trans_date_filter{
    font-size: 13px !important;
    padding: 4px !important;
}
.light_green_color {
    color:#11FFBD !important;
}
.visitor_team_border {
    border: 2px solid rgb(251, 22, 35) !important;
}
.local_team_border {
    border:  2px solid #ffb883  !important;
}
